<!--
   Created by Terence on 2023/12/7 - 18:15
-->
<template>
  <div class="post">
    <div class="post-header">
      <van-image
        fit="cover"
        class="post-header-avatar"
        :src="item?.creator?.avatarUrl + '!mfit_w480_h480_jpg'"
        alt=""
      />
      <div class="post-header-user">
        <div class="post-header-user-name">
          {{ item?.creator?.displayName }}
        </div>
        <div class="post-header-user-time">
          {{ item?.createdAt }}&nbsp;发布该帖子
        </div>
      </div>
    </div>
    <div class="post-suspect" v-if="type === 'suspect'">
      <span class="post-suspect-content">{{ item.content }}</span>
    </div>
    <div class="post-imgs" v-if="item.attachments?.length">
      <grid-imgs :imgs="item.attachments" />
    </div>
    <div class="post-comments">
      <!--  评论列表  -->
      <comment-list
        :maxCount="3"
        :extListCount="item.commentCount"
        :extList="item.comments"
        useExtList
        :postId="item.id"
        :type="type"
      />
    </div>
    <div class="post-thumb">
      <Thumb
        :likeCount="item.likeCount"
        :dislikeCount="item.dislikeCount"
        :myAction="item.myLike"
        @onClickThumb="onClickThumb"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { Image as VanImage } from "vant";
import GridImgs from "@/components/GridImgs";
import Thumb from "@/components/Thumb";
import CommentList from "@/components/CommentList/index.vue";

const emit = defineEmits("onClickThumb");

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: "default",
  },
});

const onClickThumb = () => {
  emit("onClickThumb", props.item);
};
</script>

<style lang="scss" scoped>
.post {
  &-header {
    display: flex;
    align-items: center;
    &-avatar {
      width: 76rpx;
      height: 76rpx;
      border-radius: 100%;
      overflow: hidden;
      border: 2rpx solid rgba(0, 0, 0, 0.04);
    }
    &-user {
      margin-left: 16rpx;
      &-name {
        color: #000;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 157.143% */
      }
      &-time {
        margin-top: 2rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 110% */
      }
    }
  }
  &-suspect {
    margin-top: 27rpx;
    &-content {
      white-space: pre-wrap;
      color: #000;
      font-size: 32rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 56rpx; /* 175% */
    }
  }
  &-imgs {
    margin-top: 24rpx;
  }
  &-comments {
  }
  &-thumb {
    margin-top: 32rpx;
  }
}
</style>
