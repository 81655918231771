<!--
   Created by Terence on 2023/12/7 - 14:33
-->
<template>
  <div class="suspect">
    <download-banner @onInvokeApp="onInvokeApp" />
    <hover-guide-btn @onInvokeApp="onInvokeApp" />

    <div class="suspect-header">
      <div class="suspect-header-title">{{ total }}位用户对该作品提出疑议</div>
      <div class="suspect-header-content">
        我们鼓励合理对作品提出疑议，赞同别人的疑议可以帮助其他用户识别作品问题，大量有疑议的作品，平台介入处理。如遇紧急问题可联系客服
      </div>
    </div>
    <div class="suspect-info" @click="goToTokenDetail" v-if="detailData.name">
      <div class="suspect-info-wrp">
        <van-image
          fit="cover"
          class="suspect-info-img"
          :src="detailData?.watermaskImages?.[0]?.url + '!mfit_w750_h750_jpg'"
          alt=""
        />
        <div class="suspect-info-name otext2">{{ detailData.name }}</div>
      </div>
    </div>
    <div class="suspect-content" v-if="listData.length">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="loadMoreFc"
      >
        <div class="list">
          <div class="list-item" v-for="(item, index) in listData" :key="index">
            <post-item
              :item="item"
              type="suspect"
              @onClickThumb="onInvokeApp"
            />
            <!--            <item-card :item="item" @handleClickItem="handleClickItem" />-->
          </div>
        </div>
      </van-list>
    </div>
    <div class="empty" v-else>
      <div class="empty-title">暂无质疑</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Image as VanImage, List as VanList } from "vant";
import dayjs from "dayjs";
import { time } from "@frontend/echo-utils";
import DownloadBanner from "@/components/DownloadBanner/index.vue";
import PostItem from "@/components/PostItem/index.vue";
import { getQuery, transUrlRelatedType } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";
import NftApi from "@/apis";
import HoverGuideBtn from "@/components/HoverGuideBtn/index.vue";
import { timeDiff } from "@/utils/time";

const router = useRouter();

const test = ref("");
const listData = ref([]);
const detailData = ref({});
const loading = ref(false);
const finished = ref(false);
const total = ref(0);

const onInvokeApp = (itemData) => {
  const { tokenId } = getQuery();

  const tempParams = {
    tokenId,
  };
  if (itemData?.id) {
    tempParams.topId = itemData?.id;
  }
  invokeAppByYYB({
    targetPath: "token/suspect/detail",
    params: tempParams,
  });
};

const goToTokenDetail = () => {
  const { tokenId } = getQuery();
  router.push(
    `/token-detail?tokenId=${tokenId}&publisherUserId=${detailData.value?.publisher?.userId}`
  );
};

const initListData = async ({ isReset } = { isReset: false }) => {
  // TODO 测试疑议
  // const { tokenId } = getQuery();
  const { relatedId, relatedType } = getQuery();
  loading.value = true;
  const { code, data } = await NftApi.getSuspectListByTopic({
    topicId: relatedId,
    topicType: transUrlRelatedType(relatedType),
    filterSuspectId: [],
    limit: 10,
    offset: isReset ? 0 : listData.value.length,
  });

  loading.value = false;

  detailData.value = data?.list?.[0]?.suspectedToken || {};

  let linjieAccountInfoMap = undefined;
  if (data.list.length) {
    const userIds = [];
    data.list.forEach((item) => {
      item.comments.forEach((single) => {
        if (single.user?.userId) {
          userIds.push(single.user.userId);
        }
        if (single.replyToUser?.userId) {
          userIds.push(single.replyToUser.userId);
        }
      });
    });

    // console.log('userIds===', userIds);
    const accountRes = await NftApi.getAccountInfo({ userIds: userIds });
    linjieAccountInfoMap = new Map(Object.entries(accountRes.data));
  }

  if (linjieAccountInfoMap) {
    data.list.forEach((single) => {
      single.comments.forEach((item) => {
        if (linjieAccountInfoMap.has(item.user.userId)) {
          item.user.avatar = linjieAccountInfoMap.get(
            item.user.userId
          ).avatarUrl;
          item.user.nickName = linjieAccountInfoMap.get(
            item.user.userId
          ).displayName;
          item.creator = item.user;
        }
        if (item.replyToUser?.userId) {
          item.replyToUser.avatar = linjieAccountInfoMap.get(
            item.replyToUser.userId
          ).avatarUrl;
          item.replyToUser.nickName = linjieAccountInfoMap.get(
            item.replyToUser.userId
          ).displayName;
        }

        item.createdAt = +item.createdAt;
        let timeString = timeDiff(+new Date(item.createdAt));
        item.timeString = timeString;
      });
    });
  }

  const tempData = data.list.map((item) => {
    if (item?.createdAt) {
      item.createdAt = `${dayjs(+item.createdAt).format("YYYY-MM-DD")}`;
    }
    return item;
  });
  total.value = data.total;

  console.log("tempData=", tempData);

  if (isReset) {
    listData.value = tempData;
  } else {
    listData.value = listData.value.concat(tempData);
  }
  const hasMore = listData.value.length < +total.value;
  if (hasMore) {
    finished.value = false;
  } else {
    finished.value = true;
  }
};

const loadMoreFc = () => {
  initListData();
};

// const initTokenData = async () => {
//   const { tokenId } = getQuery();
//   const { data } = await NftApi.getTokenDetail({
//     tokenId,
//   });
//   detailData.value = data?.token || {};
// };

initListData();
// initTokenData();
</script>

<style lang="scss">
.suspect {
  background-color: #f8f8f8;
  &-header {
    padding: 16rpx 30rpx;
    background-image: url("https://cdn.qiandaoapp.com/interior/images/9b64ea2ba0a2d021e83043750b6a4895.png!lfit_w1080_h1080_png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-positioin: center center;
    &-title {
      color: #fff;
      text-align: center;
      font-size: 32rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 48rpx; /* 150% */
      letter-spacing: 0.32rpx;
    }
    &-content {
      margin-top: 16rpx;
      color: rgba(255, 255, 255, 0.64);
      font-size: 24rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 36rpx; /* 150% */
    }
  }
  &-info {
    background-color: #f8f8f8;
    &-wrp {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      padding: 32rpx 30rpx;
      border-radius: 24rpx 24rpx 0 0;
      background-color: #fff;
    }
    &-img {
      flex: 0 0 192rpx;
      width: 192rpx;
      height: 192rpx;
      border-radius: 8rpx;
      overflow: hidden;
    }
    &-name {
      margin-left: 16rpx;
      color: #000;
      font-size: 32rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 48rpx; /* 150% */
    }
  }
  &-content {
    margin-top: 16rpx;
    background-color: #fff;
    .list {
      background-color: #f8f8f8;
      &-item {
        padding: 32rpx 30rpx;
        background-color: #fff;
        margin-bottom: 16rpx;
      }
    }
  }
}

.section {
  margin-top: 48rpx;
  &-title {
    color: #000;
    font-size: 40rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 52rpx; /* 130% */
  }
}

.empty {
  padding-top: 32rpx;
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  &-title {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-btn {
    margin-top: 24rpx;
    width: 100%;
    text-align: center;
  }
}
</style>
